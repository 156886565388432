import React, { useState, useEffect  , useRef} from 'react';
import { useParams } from 'react-router-dom';
import { SellerNav } from './SellerNav';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
export const DealerBidResponse = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const [bidNegotiations, setBidNegotiations] = useState([]);
  const [message, setMessage] = useState(''); // State to store success/error message
  const navigate = useNavigate();
  const storedUserId = sessionStorage.getItem('user_id');
  const [negotiationHistory, setNegotiationHistory] = useState([]);
  const [vin, setVin] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [mileage, setMileage] = useState("");
  const [firmprice, setFirmPrice] = useState("");
  const numericMileage = Number(mileage);
  const numericFirmPrice = Number(firmprice);
  const [exteriorimage, setExteriorImage] = useState("");
  const [exteriorimagetwo, setExteriorImageTwo] = useState("");
  const [exteriorimagethree, setExteriorImageThree] = useState("");
  const [exteriorimagefour, setExteriorImageFour] = useState("");
  const [counterPrice, setCounterPrice] = useState("");
  const [comments, setComments] = useState("");
  const [sellerid, setSeller] = useState("");
  const [highestbid, SetHighFetchDataalwsys] = useState('');
  const [delermessage, setShowdelermessage] = useState('');
  const prevDealerIdRef = useRef(null); // Track previous dealer ID
const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
const [Buttondisable, setIsButtondisable] = useState(""); 
const intervalRef = useRef(null);
const remainingTimeRef = useRef(0);


  const numericHighestBid = Number(highestbid);
  const formatCurrency = (value) => {
    if (!value) return '';
    const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const formattedValue = `$${Number(numericValue).toLocaleString()}`; // Format with $ and commas
    return formattedValue;
  };


  useEffect(() => {
    const interval = setInterval(() => {
        BidFetchData();
    }, 1000); // Fetch data every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
}, []); // Empty dependency array ensures this runs only once on mount
const BidFetchData = async () => {
  try {
      const response = await api.get(`/show-negotiation/${Vehicle_Id}`);
      const data = response.data.bidnegotation;

      console.log('Bid Negotiations Data:', data);

      // Check if data is null or empty
      if (!data || data.length === 0) {
          console.log('All dealers have rejected your car price.');
          setIsButtondisable(true); // Set bid time closed when timer hits 0
          setShowdelermessage('All dealers have declined the offer.');
          navigate('/future-auctions');
          return; // Exit function early
      }

      // Process data when it's not null or empty
      const currentDealerId = data[0]?.dealer_id;

      // Check if dealer ID has changed
      if (prevDealerIdRef.current !== null && prevDealerIdRef.current !== currentDealerId) {
          console.log(
              `Dealer ${prevDealerIdRef.current} rejected your car price. Now moving to Dealer ${currentDealerId}.`
          );
          setShowdelermessage(`Dealer 1 has declined the offer. Dealer 2 is now in the spotlight.`);
      }

      // Update previous dealer ID
      prevDealerIdRef.current = currentDealerId;
      console.log('Updated previous dealer ID:', prevDealerIdRef.current);

      // Update state with fetched data
      setBidNegotiations(data);
  } catch (error) {
      console.error('Error fetching Bid details:', error);
  }
};

  const handleOfferResponse = async (action, dealer) => {
    const { dealer_id, seller_id, dealer_counter_offer, dealer_comments } = dealer;
  
    // Step 1: Format counter offer price
    const formattedCounterOffer = dealer_counter_offer
      ? `$${dealer_counter_offer.toLocaleString('en-US')}`
      : null;
  
    // Step 2: Define confirmation message
    const confirmationMessage =
      action === "Accept"
        ? `Do you want to Accept this offer?`
        : "Do you want to decline this offer?";
  
    // Step 3: Show confirmation prompt using SweetAlert2
    const result = await Swal.fire({
     // title: "Confirmation",
      text: confirmationMessage,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
  
    if (!result.isConfirmed) {
      setMessage("Action was canceled. No changes made.");
      return; // Exit if the user clicks "No"
    }
  
    // Step 4: Prepare data for API call
    const data = {
      BidID: dealer.bid_id,
      VehicleID: Vehicle_Id, // Ensure this is defined in your scope
      DealerID: dealer_id,
      SellerID: seller_id,
      CounterPrice: dealer_counter_offer,
      Comments: dealer_comments,
      Action: action,
      PortalName: "Seller", // Hardcoded as per your requirement
    };
  
    try {
      // Step 5: Call API based on the action
      const response = await api.post("/negotiation", data);
      console.log("Response after action:", response.data);
  
      // Step 6: Show success message based on the action
      if (action === "Accept") {
        setMessage("Offer accepted successfully!");
      } else {
        setMessage("Offer declined successfully!");
      }
      if (action === "Decline") {
        navigate("/future-auctions"); // Redirect to the desired route
      }
    } catch (error) {
      console.error("Error responding to the offer:", error);
      setMessage(
        "There was an error processing your response. Please try again."
      );
    }
  };
  
  const handleNewCounterPrice = async () => {
    // Collect necessary IDs from the negotiations
    let bidIds = [];
    let dealerIds = [];
    if (!counterPrice) {
      Swal.fire({
        title: "Error",
        text: "Please add a counter price.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit the function
    }
    bidNegotiations.forEach((negotiation, index) => {
      console.log("Negotiation object:", negotiation);
      if (negotiation.bid_id && negotiation.dealer_id) {
        bidIds.push(negotiation.bid_id);
        dealerIds.push(negotiation.dealer_id);
      } else {
        console.warn(`Missing bid_id or dealer_id in negotiation at index ${index}`);
      }
    });

    if (bidIds.length === 0 || dealerIds.length === 0) {
      console.warn("No valid IDs found for submission.");
      return;
    }

    const data = {
      BidID: bidIds.join(","), // Join bid IDs as comma-separated if needed
      VehicleID: parseInt(Vehicle_Id),
      DealerID: dealerIds.join(","), // Join dealer IDs as comma-separated if needed
      SellerID: storedUserId,
      CounterPrice: parseFloat(counterPrice.replace(/[$,]/g, "")), // Send numeric value only
      Comments: comments,
      Action: "new",
         PortalName: "Seller"
    };

    try {
      const numericPrice = parseFloat(counterPrice.replace(/[$,]/g, ''));
      if (numericPrice <= numericHighestBid) {
        Swal.fire({
          title: "Invalid Counter Offer",
          text: `The counter offer must be higher than the highest bid of $${numericHighestBid.toLocaleString("en-US")}.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Exit the function
      } 
      const isConfirmed = await Swal.fire({
        title: "New Reserve Price",
        text: `Is ${numericPrice.toLocaleString()} your new reserve price?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      console.log("Submitting data:", data); // Log before submitting
      if (isConfirmed.isConfirmed) {
      const response = await api.post("/negotiation", data);
      console.log("Response from API:", response.data);
      fetchAllChat();
   
      if (response.status === 200) {
        Swal.fire("Success", "Negotiation submitted successfully.", "success");
        // Reset form fields after successful submission
        setCounterPrice("");
        setComments("");
      } else {
        console.error("Error submitting data:", response.data);
        alert("Failed to submit Counter Price. Please try again.");
      }
    } else {
      Swal.fire("Cancelled", "Negotiation submission canceled.", "info");
    }
    } catch (error) {
      console.error("API error:", error);
      alert("An error occurred while submitting. Please try again.");
    }
  };

  const fetchAllChat = async () => {
    try {
      const response = await api.get(`/negotiation-history-on-seller/${Vehicle_Id}/${storedUserId}`);
      console.log(response.data)
      const data = response.data.negotiations_history_for_seller
      || [];
      setNegotiationHistory(data);
    } catch (error) {
      console.error("Error fetching negotiation history:", error);
    }
  };

  useEffect(() => {
    fetchAllChat();
  }, []);
  useEffect(() => {
    if (Vehicle_Id) {
      fetchAllChat();
      const interval = setInterval(fetchAllChat, 5000); // Fetches every 5 seconds
      return () => clearInterval(interval); // Clean up on unmount
    }
  }, [Vehicle_Id]);
  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    setCounterPrice(formatCurrency(rawValue)); // Update state with formatted value
  };
  const fetchData = async () => { 
    try {
    const response = await api.get(`/seller/vehicle/${Vehicle_Id}`);
    const vehicleData = response.data;
    setVin(vehicleData.vehicle.VIN || "");
    setYear(vehicleData.vehicle.Year || "");
    setMake(vehicleData.vehicle.Make || "");
    setModel(vehicleData.vehicle.Model || "");
    setTrim(vehicleData.vehicle.Trim || "");
    setMileage(vehicleData.vehicle.Mileage || "");
    setFirmPrice(vehicleData.vehicle.Reserve_Price);
    setSeller(vehicleData.vehicle.Seller_Id);
    setExteriorImage(vehicleData.vehicle["Exterior_Image"] || "");
    setExteriorImageTwo(vehicleData.vehicle["Exterior_Image2"] || "");
    setExteriorImageThree(vehicleData.vehicle["Exterior_Image3"] || "");
    setExteriorImageFour(vehicleData.vehicle["Exterior_Image4"] || ""); 
  } catch (error) {
  console.error("Error fetching vehicle details:", error);
  }
};
useEffect(() => {
  fetchData();
  }, []);
  const fetchalwayshighest = async () => {
    try {
      const response = await api.get(`/dealer/highestbid/${Vehicle_Id}`);
      const data = response.data;
      console.log("testhighestbid",response.data)
      SetHighFetchDataalwsys(data.HighestBid);
    } catch (error) {
     // console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    fetchalwayshighest();
  }, []); // Empty array ensures this runs only once on component mount
  useEffect(() => {
    fetchAuctionTimeout();
  }, []);
  const fetchAuctionTimeout = async () => {
   try {
     const response = await api.get(`/negotiationtimeoutdetails/${Vehicle_Id}`);
     console.log(`API Response for Vehicle ${Vehicle_Id}:`, response.data);
     const totalSeconds = Math.floor(response.data.counterseconds);
     if (intervalRef.current === null) {
       startCountdown(totalSeconds); // Start countdown only if it's not already running
     } else {
       remainingTimeRef.current = totalSeconds; // If already running, just update the remaining time
     }
   
   } catch (error) {
     console.error(`Error fetching auction timeout for Vehicle ${Vehicle_Id}:`, error);
     setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
   }
 };
 useEffect(() => {
   const intervalId = setInterval(() => {
     fetchAuctionTimeout();
   }, 2000);
 
   // Cleanup interval when component unmounts
   return () => clearInterval(intervalId);
 }, [Vehicle_Id]); // Dependency array ensures firmprice is up-to-date
 
 const startCountdown = (totalSeconds) => {
   let secondsRemaining = totalSeconds;
   remainingTimeRef.current = secondsRemaining;
 
   intervalRef.current = setInterval(() => {
     if (secondsRemaining <= 0) {
       clearInterval(intervalRef.current);
       intervalRef.current = null;
       setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
       setIsButtondisable(true); // Set bid time closed when timer hits 0
       return;
     }
 
     secondsRemaining -= 1;
     remainingTimeRef.current = secondsRemaining;
 
     const hours = Math.floor(secondsRemaining / 3600);
     const minutes = Math.floor((secondsRemaining % 3600) / 60);
     const seconds = secondsRemaining % 60;
 
     setTimeLeft({ hours, minutes, seconds });
   }, 1000);
 };
 useEffect(() => {
   const fetchTimeoutAndStartTimer = async () => {
     await fetchAuctionTimeout();
 
     // Start countdown timer
     const timer = setInterval(() => {
       setTimeLeft((prev) => {
         const totalSeconds = prev.hours * 3600 + prev.minutes * 60 + prev.seconds - 1;
 
         if (totalSeconds <= 0) {
           clearInterval(timer);
           return { hours: 0, minutes: 0, seconds: 0 };
         }
 
         const hours = Math.floor(totalSeconds / 3600);
         const minutes = Math.floor((totalSeconds % 3600) / 60);
         const seconds = totalSeconds % 60;
 
         return { hours, minutes, seconds };
       });
     }, 1000);
 
     return () => clearInterval(timer); // Cleanup timer on unmount
   };
 
   fetchTimeoutAndStartTimer();
 }, []);
  return (
    <section className="car-details">
      <SellerNav />
      <div class="nego">
        <div class="container py-5">
        {delermessage && (
          <div class="container">
              <div class="row">
                  <div class="col-md-12 text-center">
                      <div class="alert alert-success mt-2">
                          <strong>{delermessage}</strong> 
                      </div>
                    </div>
              </div>
          </div>
        )}
          <div class="row">
              <div class="car-profile">
              <div class="car-image">
                  {exteriorimage && (
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                           alt="Exterior"
                           />
                     )}
                     {exteriorimagetwo && (
                    
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                           alt="Exterior"
                          
                           />
                     )}
                     {exteriorimagethree && (
                    
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                           alt="Exterior"
                           />
                     )}
                     {exteriorimagefour && (
                        <img
                           src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                           alt="Exterior"
                           />
                     )}
                  </div>
                  <ul class="profile-list">
                    <li><span>Make:</span> {make}</li>
                    <li><span>Model:</span> {model}</li>
                    <li><span>Year:</span> {year}</li>
                    <li><span>Odometer:</span> {numericMileage.toLocaleString()} Km</li>
                    <li><span>Price:</span> ${numericFirmPrice.toLocaleString()}</li>
                  </ul>
              </div>
              <div class="negotaition-panel">
                <div class="inner-panel p-4">
                  <h3 class="main-headline text-uppercase">Welcome to Car Bidding Negotiation</h3>
                  <p class="tag-line text-center">Negotiate with the seller and finalize your deal.</p>
                  <div class="message-panel">
                  <ul className="list">
                        {negotiationHistory.map((negotiation) => (
                          <li key={negotiation.id} className={negotiation.sender_role}>
                            {negotiation.sender_role === "dealer" ? (
                              <div className="dealer-pop">
                                <div className="amount">${negotiation.conter_offer.toLocaleString()}</div>
                                <p className="comments">{negotiation.message}</p>
                                <small>Dealer (ID: {negotiation.sender_id})</small>
                              </div>
                            ) : (
                              <div className="seller-pop">
                                <div className="amount">${negotiation.conter_offer.toLocaleString()}</div>
                                <p className="comments">{negotiation.message}</p>
                                <small>Seller (ID: {negotiation.sender_id})</small>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                  </div>
                  <div class="input-section bg-light p-4">
                    <label class="w-100">Counter Amount</label>
                    <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={counterPrice}
                    onChange={handlePriceChange} // Update with formatted value
                  />

                    <label class="mt-4 w-100">Comments</label>
                    <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)} // Update state on textarea change
                  ></textarea>

                    <button className="btn btn-primary mt-4"  disabled={Buttondisable}  onClick={handleNewCounterPrice}>
                    Submit
                  </button>
                  </div>

                  <div class="input-section bg-light p-4 mt-4">
                    <h6 class="text-center mb-3">Finalize Your Offer</h6>
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-success form-control"   onClick={() => handleOfferResponse('Accept', bidNegotiations[0])}  disabled={Buttondisable} >Accept Offer</button>
                      </div>
                      <div class="col-6">
                        <button class="btn btn-danger form-control "   onClick={() => handleOfferResponse('Decline', bidNegotiations[0])}  disabled={Buttondisable} >Decline Offer</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};
