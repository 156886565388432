import axios from 'axios';

export const Test = async () => {
  try {
    // Step 1: Fetch CSRF Token
    const response = await axios.get('https://backend.carchaser.ca/api/csrf-token', {
      withCredentials: true,  // Include credentials (cookies) with the request
    });
    const csrfToken = response.data.csrf_token;
    console.log('CSRF Token fetched:', csrfToken);  // Logging CSRF token

    // Step 2: Set the CSRF token in Axios default headers
    axios.defaults.headers.common['X-XSRF-TOKEN'] = csrfToken;
    console.log('CSRF Token set in headers.');

    // Step 3: Make the POST request
    const res = await axios.post('https://backend.carchaser.ca/api/auth/seller/login', {
      phone: 8872609322,
    }, {
      withCredentials: true,  // Include credentials (cookies) with the request
    });
    console.log('Login successful:', res.data);  // Logging successful response

  } catch (err) {
    // Logging error messages
    console.error('Error:', err.response ? err.response.data : err.message);
  }
};
