
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import { Login } from './Auth/Login';
import { SellerIn } from './Auth/SellerIn';
import { SellerVerification } from './Auth/SellerVerification';
import { UploadVehicleDetails } from './Seller/UploadVehicleDetails';
import { SellerNav } from './Seller/SellerNav';
import { CarEstimated } from './Seller/CarEstimated';
import { CarSellAppointment } from './Seller/CarSellAppointment';
import { SeamlessProcess } from './Seller/SeamlessProcess';
import { BookedInspection } from './Seller/BookedInspection';
import { ViewPosts } from './Seller/ViewPosts';
import { CarDetails } from './Seller/CarDetails';
import { BidHistory } from './Seller/BidHistory';
import { BidPopUp } from './Seller/BidPopUp';
import { Nagotition } from './Seller/Nagotition';
import { DealerBidResponse } from './Seller/DealerBidResponse';
import { Pdf } from './Seller/Pdf';
import { FutureAuctions } from './Seller/FutureAuctions';
import { SoldCar } from './Seller/SoldCar';
import { LiveInAuction } from './Seller/LiveInAuction';
import { UnSoldCars } from './Seller/UnSoldCars';
import { StartNegotiation } from './Seller/StartNegotiation';
import { FutureCarDetails } from './Seller/FutureCarDetails';
import { UnSoldCarDetails } from './Seller/UnSoldCarDetails';
import { NegotiationCarDetails } from './Seller/NegotiationCarDetails';
import { NegotiationBidHistory } from './Seller/NegotiationBidHistory';
import { LiveAictionCar } from './Seller/LiveAictionCar';
import { CarInNegotiation } from './Seller/CarInNegotiation';
import { Thankyou } from './Seller/Thankyou';
import { Test } from './Seller/Test';
import { Midware } from './Seller/Midware';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sellerin" element={<SellerIn />} />
          <Route path="/sellerverification" element={<SellerVerification />} />
          <Route path="/uploadvehicledetails/:vechicleid" element={<UploadVehicleDetails />} />
          <Route path="/uploadvehicledetails" element={<UploadVehicleDetails />} />
          <Route path="/SellerNav" element={<SellerNav />} />
          <Route path="/carvalue" element={<CarEstimated />} />
          <Route path="/book-appointment" element={<CarSellAppointment />} />
          <Route path="/experience-seamless" element={<SeamlessProcess />} />
          <Route path="/view-posts" element={<ViewPosts />} />
          <Route path="/car-details/:Vehicle_Id" element={<CarDetails />} />
          <Route path="/booked-inspection-appointments" element={<BookedInspection />} />
          <Route path="/bid-history/:Vehicle_Id" element={<BidHistory />} />
          <Route path="/bid/:Vehicle_Id/:Bid_Id1/:Dealer_Id1/:Bid_Id2?/:Dealer_Id2?" element={<BidPopUp />}/>
          <Route path="/nagotition-cars" element={<Nagotition />} />
          <Route path="/dealer-response/:Vehicle_Id" element={<DealerBidResponse />} />
          <Route path="/pdfgenrate" element={<Pdf />} />
          <Route path="/sold-cars" element={<SoldCar />} /> 
          <Route path="/future-auctions" element={<FutureAuctions />} />
          <Route path="/live-auction" element={<LiveInAuction />} />
          <Route path="/unsold-Cars" element={<UnSoldCars />} />
          <Route path="/start-negotiation" element={<StartNegotiation />} /> 
          <Route path="/future-car-details/:Vehicle_Id" element={<FutureCarDetails />} /> 
          <Route path="/unsold-car-details/:Vehicle_Id" element={<UnSoldCarDetails />} />  
          <Route path="/negotiation-car-details/:Vehicle_Id" element={<NegotiationCarDetails />} />  
          <Route path="/negotiation-bid-history/:Vehicle_Id" element={<NegotiationBidHistory />} />  
          <Route path="/live-auction-car/:Vehicle_Id" element={<LiveAictionCar />} /> 
          <Route path="/nagotiation-car-details/:Vehicle_Id" element={<CarInNegotiation />} />    
          <Route path="/thankyou/" element={<Thankyou />} />    
          <Route path="/test" element={<Test />} />   
          <Route path="/midware/:Vehicle_Id/:Dealer_Id" element={<Midware />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
