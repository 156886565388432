import React, { useState, useEffect } from 'react';
import { SellerNav } from './SellerNav';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { format } from 'date-fns';
import ReactApexChart from 'react-apexcharts'; // Import ApexCharts wrapper
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export const CarEstimated = () => {
    const navigate = useNavigate();
    const [location, setLocation] = useState('');
    const [vin, setVin] = useState('');
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [mileage, setMileage] = useState('');
    const [exteriorimage, setExteriorImage] = useState('');
    const [exteriorimagetwo, setExteriorImageTwo] = useState('');
    const [exteriorimagethree, setExteriorImageThree] = useState('');
    const [exteriorimagefour, setExteriorImageFour] = useState('');
    const [sessionVehicleId, setSessionVehicleId] = useState('');
    const [getPrice, setGetPrice] = useState('');
    const [averageprice, setGetaveragePrice] = useState('');
    const [belowprice, setGetbelowPrice] = useState('');
    const [firmPrice, setFirmPrice] = useState('');
    const storedVehicleId = sessionStorage.getItem('Vehicle_Id');
    const storedEditMode = sessionStorage.getItem('Edit_Mode');
    const editModepass = storedEditMode === null ? 'normaledit' : storedEditMode;
    const [fadingpaints, setFadingPaints] = useState('');
    const [rust, setRust] = useState('');
    const [minordamage, setMinorDamage] = useState('');
    const [dents, setDents] = useState('');
    const [haildamage, setHailDamage] = useState('');
    const [ripsOrTears, setRipsOrTears] = useState(false); 
    const [visibleStain, setVisibleStain] = useState(false);
    const [strongSmell, setStrongSmell] = useState(false);
    const [damagedSystems, setDamagedSystems] = useState(false);
    const [hasOriginalRims, setHasOriginalRims] = useState(null);
    const [tireReplacement, setTireReplacement] = useState(null); 
    const [crackOnWindshield, setCrackOnWindshield] = useState(null);
    const [deliveryOption, setDeliveryOption] = useState('');  // 'Pickup' or 'Dropoff'
    const [pickupAddress, setPickupAddress] = useState('');    // Address for Pickup option
    const carChaserAddress = "801- 1275 Finch Ave W,North York ON M3L 0L5";   // Predefined Car Chaser address
    const [expectedDate, setExpectedDate] = useState('');  // State for storing expected date
    const [biddingDate, setBiddingDate] = useState('');
    const [biddingHour, setBiddingHour] = useState('');
    const [biddingMinute, setBiddingMinute] = useState('00');
    const [biddingPeriod, setBiddingPeriod] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [reeserprice, setReservePrice] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [isFieldEmpty, setIsFieldEmpty] = useState(false);
    const [lastMinute, setLastMinute] = useState(''); 
    const [editMode, setEditMode] = useState(null); 
    const [selectedDate, setSelectedDate] = useState(null);
    const [isformated, setisformated] = useState(null); 
    const todayString  = new Date().toLocaleDateString('en-CA', { timeZone: 'America/Toronto' });
    const currentDateTimeToronto = new Date().toLocaleString('en-CA', { timeZone: 'America/Toronto', hour12: false });
    console.log('canada',currentDateTimeToronto);
    const currentHourToronto = new Date(currentDateTimeToronto).getHours();
    console.log('canada hour',currentHourToronto);
    const currentMinuteToronto = new Date(currentDateTimeToronto).getMinutes();
    console.log('canada minute',currentMinuteToronto);
    const adjustedHour = currentMinuteToronto > 0 ? currentHourToronto + 1 : currentHourToronto;
    console.log('canada adjusted hour',adjustedHour);
    const [nextAvailableDate, setNextAvailableDate] = useState(null);
    const storedUserId = sessionStorage.getItem('user_id');
    console.log("Stored User ID:", storedUserId);
    useEffect(() => {
      const fetchCalendarMode = async () => {
          try {
              const response = await api.get(`/seller/calendervisibility/${storedVehicleId}/${editModepass}`);
              const status = response.data.Status; // No need to compare, directly use it
              setEditMode(status);
          } catch (error) {
              console.error('Error fetching calendar mode:', error);
              setEditMode(false); // Default to false on error
          }
      };
  
      fetchCalendarMode();
  }, [storedVehicleId, editModepass]);
  

    useEffect(() => {
      const fetchLastTime = async () => {
        try {
          const response = await api.get('/checkauctiontimeout');
          setLastMinute(response.data.result);
        } catch (error) {
          console.error('Error fetching last auction time:', error);
        }
      };
      fetchLastTime();
    }, []);
    

  // Calculate the last available time
  const calculateLastTime = () => {
    const lastValidTime = new Date();
    lastValidTime.setHours(19,30, 0, 0); // Default to 6:00 PM
    const lastTime = parseInt(lastMinute, 10);
  
    if (!isNaN(lastTime)) {
      lastValidTime.setMinutes(lastValidTime.getMinutes() - lastTime);
    } else {
      console.error('Invalid last minute value:', lastMinute);
    }
  
    return {
      hour: lastValidTime.getHours(),
      minute: lastValidTime.getMinutes(),
    };
  };

  const { hour: lastHour, minute: lastMinuteAvailable } = calculateLastTime();



  const handleDateChangetrue = (date) => {
    if (!date) return; // Prevent errors if no date is selected
  
    const selectedDate = new Date(date); // Safari-compatible
    const formattedDate = selectedDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
    setisformated(formattedDate);
    setBiddingDate(selectedDate);
    setSelectedDate(selectedDate);
  
    if (formattedDate === todayString) {
      if (adjustedHour < lastHour || (adjustedHour === lastHour && currentMinuteToronto <= lastMinuteAvailable)) {
        setErrorMessage('');
        setBiddingHour(adjustedHour.toString().padStart(2, '0'));
        setBiddingMinute(Math.ceil(currentMinuteToronto / 5) * 5);
      } else {
        setBiddingHour('');
        setBiddingMinute('');
        setErrorMessage("Please select another date, today’s time is not available.");
      }
    } else {
      setBiddingHour('09');
      setBiddingMinute('00');
      setErrorMessage('');
    }
  };


  const generateTimeSlots = (startHour, endHour, startMinute = 0) => {
    const slots = [];
    let currentHour = startHour;
    let currentMinute = startMinute;
  
    for (let hour = currentHour; hour <= endHour; hour++) {
      for (let minute = currentMinute; minute < 60; minute += 5) {
        if (hour === lastHour && minute > lastMinuteAvailable) break;
  
        const displayHour = hour % 12 === 0 ? 12 : hour % 12;
        const period = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
  
        slots.push(
          <option key={`${formattedHour}:${formattedMinute}`} value={`${formattedHour}:${formattedMinute}`}>
            {`${displayHour}:${formattedMinute} ${period}`}
          </option>
        );
      }
      currentMinute = 0; // Reset minutes after each hour
    }
  
    return slots;
  };
    const getNextDay = (date) => {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay.toISOString().split('T')[0];
    };
    const handleDeliveryOptionChange = (e) => {
      setDeliveryOption(e.target.value);
      if (e.target.value === 'Dropoff') {
        setPickupAddress(carChaserAddress);  // Automatically set to Car Chaser address if Dropoff is selected
      } else {
        setPickupAddress('');  // Reset address if Pickup is selected
      }
    };
    useEffect(() => {
    if (storedVehicleId) {
         fetchData();
    } else {
         console.error('Vehicle Id is null');
    }
    }, [storedVehicleId]);

    const fetchData = async () => {
        try {
            const response = await api.get(`/seller/vehicle/${storedVehicleId}`);
            const vehicleData = response.data.vehicle;
         
        if (vehicleData.Live_Auction_DateTime) {
          const [date, time] = vehicleData.Live_Auction_DateTime.split(" ");
          const [hour, minute] = time.split(":");

          // Convert date string to a Date object
          const [year, month, day] = date.split("-");
          const biddingDateObject = new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
            console.log('get date from db', biddingDateObject)
          setBiddingDate(biddingDateObject); // Set as Date object
          setBiddingHour(hour);
          setBiddingMinute(minute);
      }
         
            setDeliveryOption(vehicleData.Delivery_Mode || '')
            setPickupAddress(vehicleData.Delivery_Address || '') 
            const fetchedReservePrice = vehicleData.Reserve_Price || '';
            setFloorPrice(fetchedReservePrice.toString());
            setRust(vehicleData.Rust || '')
            setFadingPaints(vehicleData.Fading_Paints || '');
            setLocation(vehicleData.Location || '');
            setFirmPrice(vehicleData.firm_price || '');
            setVin(vehicleData.VIN || '');
            const vin = vehicleData.VIN;
            setYear(vehicleData.Year || '');
            const year = vehicleData.Year;
            setModel(vehicleData.Model || '');
            const model = vehicleData.Model;
            setTrim(vehicleData.Trim || '');
            const trim = vehicleData.Trim;
            setMileage(vehicleData.Mileage || '');
            const mileage = vehicleData.Mileage;
            setMake(vehicleData.Make || '');
            const make = vehicleData.Make;
            
            if (vehicleData.Rust === 1) {
                 setRust('Rust');
            }else {
                 setRust('');
            }
            if (vehicleData.Fading_Paints === 1) {
                setFadingPaints('Fading Paints');
            }else{
                 setFadingPaints('');
            }
            if (vehicleData.Hail_Damage === 1) {
                  setHailDamage('Hail Damage');
            }else{
                 setHailDamage(''); 
            }
            if (vehicleData.Dents === 1) {
                 setDents('Dents');
            }else{
                 setDents('');
            }
            if (vehicleData.Minor_Damage === 1) {
                setMinorDamage('Minor Damage');
            }else{
                setMinorDamage('');
            }
            if (vehicleData.Nav_Entmt_Ctrl_Dmg_Sys === 1) {
                setDamagedSystems('Damaged Systems');
            }else{
                setDamagedSystems('');
            }
            if (vehicleData.Strong_Smell === 1) {
                setStrongSmell('Strong Smell');
            }else{
                setStrongSmell('');
            }
            if (vehicleData.Seats_Visible_Stain === 1) {
                setVisibleStain('Visible Stain On The Seats');
            }else{
                setVisibleStain('');
            }
            if (vehicleData.Seats_Rips_Tears === 1) {
                setRipsOrTears('Rips Or Tears On The Seats');
            }else{
                setRipsOrTears('');
            }
            if (vehicleData.Original_Factory_Rims === 1) {
                setHasOriginalRims('Yes');
            }else{
                setHasOriginalRims('');
            }
            if (vehicleData.Tires_Repld_12_Months === 1) {
                setTireReplacement('Yes');
            }else{
                setTireReplacement('');
            }
            if (vehicleData.Windshield_Crack === 1) {
                setCrackOnWindshield('Yes');
            }else{
                setCrackOnWindshield('');
            }
            setExteriorImage(vehicleData['Exterior_Image'] || '');
            setExteriorImageTwo(vehicleData['Exterior_Image2'] || '');
            setExteriorImageThree(vehicleData['Exterior_Image3'] || '');
            setExteriorImageFour(vehicleData['Exterior_Image4'] || '');
            try {
                const response = await api.get(`/seller/vehicleofferprice/${storedVehicleId}/${vin}/${mileage}/${trim}/${year}/${make}/${model}`);
                const offerprice = response.data.third_price;
                const average = response.data.second_price;
                const belowprice = response.data.first_price;
            
                // Create an array and sort it
                const prices = [offerprice, average, belowprice].sort((a, b) => a - b);
            
                // Assign sorted values to respective price categories
                setGetbelowPrice(prices[0]); // Lowest price
                setGetaveragePrice(prices[1]); // Middle price
                setGetPrice(prices[2]); // Highest price
            } catch (error) {
                console.error('Error fetching offer price:', error);
            }

        } catch (error) {
            console.error('Error fetching vehicle details:', error);
        }
    };
  
    // Deduction of price by Checks
    const rustValue = rust === 'Rust' ? 800 : 0;
    const fadingPaintsValue = fadingpaints === 'Fading Paints' ? 750 : 0;
    const haildamgeValue = haildamage === 'Hail Damage' ? 800 : 0;
    const dentsValue = dents === 'Dents' ? 700 : 0;
    const minordamageValue = minordamage === 'Minor Damage' ? 300 : 0;
    const ripsOrTearsvalue  = ripsOrTears === 'Rips Or Tears On The Seats' ? 750 : 0;
    const visibleStainvalue = visibleStain === 'Visible Stain On The Seats' ? 350 : 0;
    const strongSmellvalue = strongSmell === 'Strong Smell' ? 350 : 0;
    const damagedSystemsvalue = damagedSystems === 'Damaged Systems' ? 1500 : 0;
    const hasOriginalRimsvalue = hasOriginalRims === 'Yes' ? 1000 : 0;
    const tireReplacementvalue = tireReplacement === 'Yes' ? 1000 : 0;
    const crackOnWindshieldvalue = crackOnWindshield === 'Yes' ? 800 : 0;
    const totalDeductions = rustValue + fadingPaintsValue + haildamgeValue + dentsValue + minordamageValue + ripsOrTearsvalue + visibleStainvalue + strongSmellvalue + damagedSystemsvalue + hasOriginalRimsvalue + tireReplacementvalue + crackOnWindshieldvalue;
    const finalPrice = averageprice - totalDeductions;
    const [floorPrice, setFloorPrice] = useState('');
    const handlePriceChange = (value) => {
        // Remove any non-numeric characters (except for the decimal point)
        const numericValue = value.replace(/[^0-9.]/g, '');
        setFloorPrice(numericValue);  // Store only the numeric value
    };
    
    
    const formatWithCommas = (value) => {
    // Ensure the value is a number and format it with commas and two decimal places
        if (isNaN(value) || value === null || value === undefined) {
            return '$0.00';
        }
        return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        };
    // Chart options and data
        const chartOptions = {
            chart: {
                type: 'area',
                height: 250
            },
            series: [{
                name: 'Price',
                data: [belowprice, averageprice, getPrice]
            }],
            xaxis: {
                categories: ['Below Market', 'Average Market', 'Above Market']
            },
            grid: {
                show: false
            },
                tooltip: {
                y: {
                formatter: (value) => formatWithCommas(value)
                }
            }
        };
        const today = new Date(); // Ensure 'today' is initialized correctly
today.setHours(0, 0, 0, 0); // Normalize today's date to ignore time
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

// Normalize a date to remove the time component
const normalizeDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
const getNextAvailableDate = () => {
    let date = new Date(); // Start from today
    do {
      date.setDate(date.getDate() + 1);
    } while (isDateDisabled(date));
    return date;
  };
  useEffect(() => {
    // Calculate and set the next available date
    const nextDate = getNextAvailableDate();
    setNextAvailableDate(nextDate);
  }, []);

// Function to check if a date should be disabled (disable today, tomorrow, and weekends)
const isDateDisabled = (date) => {
    const normalizedDate = normalizeDate(date);
    const day = normalizedDate.getDay();

    // Disable today and tomorrow explicitly
    if (normalizedDate <= today || normalizedDate.toDateString() === tomorrow.toDateString()) {
        return true;
    }

    // Allow only Monday (1), Wednesday (3), and Friday (5)
    return day !== 1 && day !== 3 && day !== 5;
};



// Handle date change
const handleDateChange = (date) => {
    const normalizedDate = normalizeDate(date);

    if (normalizedDate < today) {
        setErrorMessage("The selected date cannot be in the past.");
        setBiddingDate(null);
        return;
    }

    setBiddingDate(normalizedDate);
    setErrorMessage('');
};

// Post data validation
const postDataFirmPrice = async () => {
  const today = new Date().toISOString().split('T')[0];
          if (floorPrice === "") {
              alert("Please enter a Reserve price.");  // Alert if the field is empty
              return;
            }  
            const price = parseFloat(floorPrice);  // Convert the input to a number
  
            if (price === 0) {
              alert("Reserve price cannot be 0. Please enter a valid amount.");  // Alert if the value is 0
              return;
            }
          
            if (isNaN(price) || price < 0) {
              alert("Please enter a valid Reserve price greater than 0.");  // Alert for invalid or negative numbers
              return;
            }
          if (!deliveryOption) {
              alert("Please select a delivery mode.");  // Show alert if no option is selected
              return;
          }
          if (pickupAddress === "") {
              alert("Please Enter a address for pickup.");  // Alert if the field is empty
              return;
            }  
            if (biddingDate === "") {
              alert("Please select Live Auction Date and Time.");  // Alert if the field is empty
              return;
            }  
            if (selectedDate === "") {
              alert("Please select Live Auction Date and Time.");  // Alert if the field is empty
              return;
            }
            
            if (biddingHour === "") {
              alert("Please select Live Auction Time.");  // Alert if the field is empty
              return;
            } 
            if (biddingDate < today) {
              alert("You cannot select a past date. Please select today or a future date.");
              return;
          }
         
          if (expectedDate === "") {
              alert("Please select Pick up or drop off date.");  // Alert if the field is empty
              return;
            } 
            try {
              // Ensure `biddingHour` and `biddingMinute` are strings
              const formattedHour = String(biddingHour).padStart(2, '0'); // Ensure two digits for hour
              const formattedMinute = String(biddingMinute).padStart(2, '0'); // Ensure two digits for minute
          
              // Combine the selected date with the selected time
              const fullBiddingDateTime = `${format(biddingDate, 'yyyy-MM-dd')} ${formattedHour}:${formattedMinute}:00`; // e.g., "2024-10-28 14:30:00"
              console.log("when submit the selected date",fullBiddingDateTime)
              // Prepare data for the API request
              const data = {
                  reserve_price: floorPrice,
                  bidding_date: fullBiddingDateTime,
                  delivery_option: deliveryOption,
                  pickup_address: deliveryOption === 'Pickup' ? pickupAddress : carChaserAddress,
                  expected_date: expectedDate,
              };
              if (!editModepass) {
                console.error('Edit mode is not set or invalid.');
                return; // Stop execution if edit mode is not set
            }
            console.log('Edit mode passed:', editModepass);
              // Make the API request
              const response = await api.post(`/seller/updatefirmprice/${storedVehicleId}/${editModepass}`, data);
              console.log(response.data)
              sessionStorage.removeItem('Edit_Mode');
              console.log('Cache cleared.');
              navigate(`/thankyou`);
          
          } catch (error) {
              console.error('Error updating firm price:', error);
          }
      };
return (
<section className="car-details">
   <SellerNav />
   <div class="average-newlayout my-5">
    <div className="container">
    <div class="info-image">
        <div class="info-image-inner">
        {(exteriorimage || exteriorimagetwo || exteriorimagethree || exteriorimagefour) && (
               <img
               src={`https://backend.carchaser.ca/uploads/${storedVehicleId}/${exteriorimage || exteriorimagetwo || exteriorimagethree || exteriorimagefour}`}
               alt="Exterior"
               />
               )}
            </div>
         </div>
         <h2 class="car-esti-heading mt-5">{year} {make}</h2>
         <p class="fw-bold"><strong>{model}</strong> | {trim}</p>
         <div class="estimate-heading-infonew">
            <h5>Car Chaser's Estimated Value Range for Your Car.</h5>
            <p>Our estimated range provides a preliminary evaluation by leveraging competition within our dealer network. This estimate serves as a guide for determining your car's price.</p>
         </div>
      {
        /*
      <div id="mountainChart">
         <ReactApexChart options={chartOptions} series={chartOptions.series} type="area" height={350} />
      </div>
      */
      }
  <div className="estimate-average-new pt-3">
    <div className="row">
        <div className="col-lg-4 col-4 fw-bold">
            <div className="circle-grey mb-2">
                <span></span>
            </div>
            <p>low</p>
            <p>${belowprice ? belowprice.toLocaleString() : '0'}</p>
        </div>
        <div className="col-lg-4 col-4 fw-bold text-center">
            <div className="circle-grey mb-2 ml-4">
                <span class="second-disc"></span>
            </div>
            <p>average</p>
            <p>${averageprice ? averageprice.toLocaleString() : '0'}</p>
        </div>
        <div className="col-lg-4 col-4 fw-bold text-right">
            <div className="circle-grey mb-2 ml-4">
                <span class="third-disc"></span>
            </div>
            <p>awesome</p>
            <p>${getPrice ? getPrice.toLocaleString() : '0'}</p>  {/* Show highest price */}
        </div>
    </div>
</div>

   <div class="reserve-price-new mt-4">
       <div class="reserve-combo">
        <h5>Reserve price</h5>
        <div
        onMouseEnter={() => setIsHovered(true)}  // Show help text on hover
        onMouseLeave={() => setIsHovered(false)} // Hide help text when not hovering
        style={{ display: 'inline-block', marginLeft: '10px'}} // Ensure correct positioning
      >
        <img src="../images/help.png" alt="help" style={{ cursor: 'pointer' }} />
        
        {/* Conditionally render the help text */}
        {isHovered && (
          <span className="help-text" style={{
            position: 'absolute',
            top: '4%',  // Position below the image
            left: '103%',
            transform: 'translateX(-50%)',
            backgroundColor: '#f9f9f9',
            border: '1px solid #ddd',
            padding: '3px 10px',
            borderRadius: '5px',
            fontSize: '12px',
            color: '#333',
            width: '385px',            
            zIndex: 1
          }}>
            The fastest way to sell your car is by choosing a realistic reserve price
          </span>
        )}
      </div>
      </div>
        <input
        type="text"  // Use 'text' to allow formatting with $ and commas
        className="form-control"
        value={floorPrice ? `$${parseFloat(floorPrice).toLocaleString()}` : ''}    // Format with $ and commas
        placeholder={reeserprice}
        onChange={(e) => handlePriceChange(e.target.value)}  // Handle change with custom function
    />
     </div>
   
     <div className="average-newbidding mt-4">
    <div className="row">
        <div className="col-lg-6 col-6">
            <div className="form-check mb-2">
                <label>
                    <input 
                        type="radio" 
                        className="form-check-input"  // Proper className for React
                        value="Pickup" 
                        checked={deliveryOption === 'Pickup'} 
                        onChange={handleDeliveryOptionChange}
                    />
                    Pickup
                </label>
            </div>
        </div>
        <div className="col-lg-6 col-6">
            <div className="form-check mb-2">
                <label>
                    <input 
                        type="radio" 
                        className="form-check-input"  // Proper className for React
                        value="Dropoff" 
                        checked={deliveryOption === 'Dropoff'} 
                        onChange={handleDeliveryOptionChange}
                    />
                    Dropoff
                </label>
            </div>
        </div>
    </div>
    <div className="row">
        {deliveryOption === 'Dropoff' && (
            <div className="col-lg-9 col-12">
                <div className="form-average">
                     <h5>CarChaser’s address: 801-1275 Finch Ave W.</h5>
                    <textarea 
                        className="form-control"  // Use form-control for better layout
                        rows="2"  // Ensure enough rows for better visibility
                        value={carChaserAddress} 
                        disabled 
                    />
                </div>
            </div>
        )} 
        {deliveryOption === 'Pickup' && (
            <div className="col-lg-9 col-12">
                <div className="form-average">
                    <h5>Pickup within the GTA is $165</h5>
                    <textarea 
                        className="form-control"  // Use form-control for better layout
                        rows="4"  // Ensure enough rows for better visibility
                        value={pickupAddress} 
                        onChange={(e) => setPickupAddress(e.target.value)} 
                        placeholder="Enter address for pickup"
                    />
                </div>
            </div>
        )}
    </div>
    <div className="average-newbidding mt-4">
        <div className="row">
        <div className="col-lg-6 ">
        <div className="average-time"> 
  <h5>Live Auction Date and Time</h5>
  <div className="average-timeinner">

            <div class="bidding-date">
                <label> Date</label>


                <>
                {editMode === false ? (
                <>
                                    <DatePicker
                    selected={biddingDate}
                    onChange={handleDateChange}
                    filterDate={(date) => !isDateDisabled(date)}
                    minDate={new Date()} // Disable past dates
                    placeholderText="Select a Date"
                    className="form-control first"
                    dateFormat="yyyy-MM-dd"
                    />
                    {nextAvailableDate && (
                    <div style={{ marginTop: "10px", color: "blue", fontWeight: "bold" }}>
                        The next available date is{" "}
                        {nextAvailableDate.toLocaleDateString("en-US", {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                        })}
                    </div>
                    )}
                </>
        ) : (
          <DatePicker
          selected={selectedDate}
          onChange={handleDateChangetrue}
          minDate={new Date()} // Disable past dates
          placeholderText="Select a Date"
          className="form-control second"
          dateFormat="yyyy-MM-dd"
      />
        )} 
    </>
               
            </div>
            <div className="bidding-time">
            <label>Time</label>
{biddingDate === todayString || isformated === todayString ? (
  <select
  class="test"
  value={editMode === false ? (biddingHour && biddingMinute ? `${biddingHour}:${biddingMinute}` : "") : (biddingHour && biddingMinute ? `${biddingHour}:${biddingMinute}` : "")}
    onChange={(e) => {
      const [hour, minute] = e.target.value.split(':');
      setBiddingHour(hour);
      setBiddingMinute(minute);
    }}
  >
    {/* Placeholder option */}
    <option value="" disabled>
      Select Time
    </option>
    {/* Generate time slots starting from the max of adjustedHour and 9 AM, considering the backend's last available time */}
    {generateTimeSlots(
      Math.max(adjustedHour, 9),
      lastHour,
      Math.ceil(currentMinuteToronto / 5) * 5
    )}
  </select>
) : (
  <select
    value={biddingHour && biddingMinute ? `${biddingHour}:${biddingMinute}` : ""}
    onChange={(e) => {
      const [hour, minute] = e.target.value.split(':');
      setBiddingHour(hour);
      setBiddingMinute(minute);
    }}
  >
    {/* Placeholder option */}
    <option value="" disabled>
      Select Time
    </option>
    {/* For future dates, always start from 9 AM */}
    {generateTimeSlots(9, lastHour, 0)}
  </select>
)}
    </div>
           
        </div>

  {/* Show error message if there is an error */}
  {errorMessage && <p className="error-message text-alert">{errorMessage}</p>}
</div>
</div>
   
            <div className="col-lg-6 ">
                <div className="average-time">
                    <h5>Pick up or drop off date</h5>
                    <div class="bidding-date">
                    <label> Date</label>
                    <input 
                    type="date" 
                    className="form-control third"
                    onChange={(e) => setExpectedDate(e.target.value)}  
                    min={biddingDate ? getNextDay(biddingDate) : today}
                />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  {/* Show error message if there is an error */}
  {errorMessage && <p className="error-message text-danger">{errorMessage}</p>}
 
<div className="estimate-button pb-5">
    {/* Conditionally render the button and disable it if biddingHour or biddingMinute is not selected or there is an error */}
    <button 
        className="btn btn-primary" 
        onClick={postDataFirmPrice}
         // Disable button if time not selected or there's an error
    >
        Sell my car ASAP
    </button>
</div>


 
   </div>
   </div>
</section>

);
};