import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // import useNavigate and useParams
import { SellerNav } from './SellerNav';
import api from '../api';

export const NegotiationBidHistory = () => {
  const { Vehicle_Id } = useParams(); // Get the Vehicle_Id from the URL slug
  const [topBids, setTopBids] = useState([]);
  const [totalBids, setTotalBids] = useState(0);
  const navigate = useNavigate(); // Use for navigation

  const fetchBidHistory = async () => {
    try {
      const response = await api.get(`/bidhistoryonstartnegotiation/${Vehicle_Id}`);
      const data = response.data;
      setTopBids(data.TopBids); // Set TopBids
      setTotalBids(data.TotalBids); // Set TotalBids
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    if (Vehicle_Id) {
      fetchBidHistory();
    }
  }, [Vehicle_Id]);

  // Function to handle the negotiation navigation
  const handleStartNegotiation = () => {
    if (topBids.length >= 1) {
      const topBid1 = topBids[0] || { Bid_Id: '', Dealer_Id: '' }; // First bid or empty
      const topBid2 = topBids[1] || { Bid_Id: '', Dealer_Id: '' }; // Second bid or empty
  
      // Ensure fallback values are meaningful and compatible with backend
      navigate(`/bid/${Vehicle_Id}/${topBid1.Bid_Id || ''}/${topBid1.Dealer_Id || ''}/${topBid2.Bid_Id || ''}/${topBid2.Dealer_Id || ''}`);
    } else {
      // Alert if there are no bids
      alert('Not enough bids to start negotiation');
    }
  };
  
  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        {/* Bidding History Section */}
        <div className="bidding-historyinfo mt-5">
          <h3 className="main-heading text-center">Bid History</h3>
          <div className="bid-historyinner mt-4">
            <div className="table-panel">
              <table className="table table-bordered mid-table">
                <thead>
                  <tr>
                    <th scope="col">Bid Amount</th>
                    <th scope="col">Bid DATE-TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {topBids.length > 0 ? (
                    // Only show the first two bids
                    topBids.slice(0, 2).map((bid) => (
                      <tr key={bid.Bid_Id}>
                        <td>
                          ${parseFloat(bid.Bid_Amount).toLocaleString()}
                          <img
                            className="pl-2"
                            src="https://seller.carchaser.ca/images/logo.png"
                            alt="logo"
                          />
                        </td>
                        <td>{new Date(bid.Bid_Date).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        No Top Bids Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row m-0">
                <div className="col-md-12 text-center">
                  <div className="text-center ml-3">
                    <button
                      onClick={handleStartNegotiation}
                      className="btn btn-xs btn-primary ml-2"
                    >
                      Start Negotiation With Top {totalBids}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
